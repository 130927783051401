<template>
	<article class="view-login bg-wave-gradient">
		<div class="site-wrapper flex-row flex-center-h flex-center-v">
			<div class="login-register flex-row flex-center-h">
				<section class="section--login flex-row flex-center-h flex-center-v">
					<header>
						<img role="img"
								 aria-hidden="true"
								 src="@/assets/svg/infovac-icon.svg"
								 width="100px" />
						<h1>{{ $t("Connexion") }}</h1>
						<!-- <p role="alert" class="alert-info flex-item--100">
              Votre ancien compte Infovac n'est pas valide sur cette nouvelle plateforme, vous devez vous en créer un nouveau.
            </p> -->
					</header>

					<div class="flex-col flex-center"
							 v-if="authToken && isLoading">
						<!-- By Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL -->
						<svg class="loader"
								 width="44"
								 height="44"
								 viewBox="0 0 44 44"
								 xmlns="http://www.w3.org/2000/svg"
								 stroke="currentColor">
																																              <g fill="none" fill-rule="evenodd" stroke-width="2">
																																                <circle cx="22" cy="22" r="1">
																																                  <animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite" />
																																                  <animate attributeName="stroke-opacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite" />
																																                </circle>
																																                <circle cx="22" cy="22" r="1">
																																                  <animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite" />
																																                  <animate attributeName="stroke-opacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite" />
																																                </circle>
																																              </g>
																																            </svg>

						<p class="hint">{{ $t("Veuillez patienter…") }}</p>
					</div>

					<!-- TODO: mailto-->
					<div v-if="!authToken || authError">
						<p class="text-center">{{ $t("Connectez-vous à la plateforme depuis votre compte sur") }} <a href="https://infovac.ch">infovac.ch</a></p>
						<p class="text-center"
							 v-if="authError">
							<a href="#">{{ $t("Contactez-nous") }}</a> {{ $t("si vous rencontrez des difficultés à vous connecter.") }}
						</p>
					</div>
				</section>
			</div>
		</div>
	</article>
</template>

<style lang="scss" scoped>
@import "src/scss/login.scss";
</style>

<script>
import { HTTP } from "../http-common";
import { EventBus } from "../event-bus.js";
// import { log } from "logrocket";

export default {
	name: "login",

	data: function () {
		return {
			authError: false,
			isLoading: false,
			authToken: "",
			loginData: {
				password: "",
				login: "",
			},
		};
	},

	computed: {
		redirectURL() {
			return this.$store.state.redirect_uri || false;
		},
		// Utilisateur connecté ?
		isLoggedIn: function () {
			return !!this.$store.state.userToken && !!this.$store.state.userData && this.$store.state.userData.registration_complete;
		},
	},

	methods: {
		// Connexion de l'utilisateur
		loginUser: async function () {
			this.isLoading = true;

			// clear dashboard filters for the session
			await this.$store.dispatch("STORE_DASHBOARD_FILTERS", "");

			this.$store
				.dispatch("USER_LOGIN")
				.then((response) => {
					this.isLoading = false;
					// EventBus.$emit("trackEvent", "Connexion", `Utilisateur ${response.data.payload.id} connecté`);

					// Set user language
					const lang = response?.data?.payload?.lang;
					if (lang) {
						this.$loadLanguageAsync(lang).then((loadedLang) => {
							// Save lang to store
							this.$store.dispatch("LANG", loadedLang);
						}).catch((error) => console.log("loadLanguageAsync", error));
					}

					// User logged in, redirect to dashboard or to previous URL?
					if (this.redirectURL) {
						const url = this.redirectURL;
						this.$store.dispatch("SET_REDIRECT_URI", null); // reset redirection
						this.$router.push(url);
					} else {
						this.$router.push("/dashboard");
					}
				})
				.catch((err) => {
					this.isLoading = false;
					this.authError = true;

					if (!err) {
						// EventBus.$emit("trackEvent", "Connexion", "Échec de connexion : erreur inconnue");
						console.warn("err unedfined?");
						return this.$toasted.global.appError({
							message:
								"<strong>" + this.$t("Erreur inconnue !") + '</strong><br class="br-mobile">' + this.$t("Veuillez vous reconnecter à votre compte") + ' <a href="https://infovac.ch">infovac.ch</a>',
						});
					} else {
						let message = "<strong>" + this.$t("Erreur d’authentification") + "</strong> " + this.$t("veuillez contacter l’administrateur d’Infovac.");

						if (err.status === 401) {
							message = "<strong>" + this.$t("Votre compte est bloqué") + "</strong>" + this.$t("veuillez contacter l’administrateur d’Infovac.");
						}

						if (err.status === 404) {
							message = "<strong>" + this.$t("Compte introuvable") + "</strong> " + this.$t("veuillez contacter l’administrateur d’Infovac.");
						}

						// 401: User account disabled
						// EventBus.$emit("trackEvent", "Connexion", "Échec de connexion : compte non vérifié");
						return this.$toasted.global.appError({
							message: message,
						});
					}
				});
		},

		// Authentification et Connexion de l'utilisateur
		signinUser: function () {
			this.isLoading = true;

			this.$store
				.dispatch("AUTH_REQUEST", this.authToken)
				.then(() => {
					// EventBus.$emit("trackEvent", "Authentification", "Authentification réussie");
					this.$toasted.clear();
					this.isLoading = false;

					// Authorization Header (JWT)
					// (Si un token est déjà enregistré en localStorage, il est passé à chaque requête.)
					// -> L'authentification (AUTH_REQUEST) s'est déroulée avec succès : on ajoute le token à toutes les requêtes :
					HTTP.defaults.headers.common.Authorization = "Bearer " + this.$store.state.userToken;

					// Connexion distante
					this.loginUser();
				})
				.catch((error) => {
					// Authentification error (invalid token, …)
					this.isLoading = false;
					this.authError = true;
					this.$toasted.clear();
					this.$toasted.global.appError({
						message:
							"<strong>" + this.$t("Session expirée") + '</strong> <br class="br-mobile">' + this.$t("Veuillez vous reconnecter à votre compte") + ' <a href="https://infovac.ch">infovac.ch</a>',
					});
					// EventBus.$emit("trackEvent", "Authentification", "Erreur d’authentification");
				});
		},

		// Logout
		logoutUser: function (event) {
			this.$store.dispatch("USER_LOGOUT").then(() => this.$router.push("/login"));
		},
	},

	async mounted() {
		/*
			If there's an auth token (from a magic link) 
			- logout
			- sign-in (get access token)
			- then login (get user data)
	
			If there's no token provided but the user is logged-in; goto dashboard.
		*/

		// Get auth token from magic link
		this.authToken = this.$route?.query?.t;

		if (this.authToken) {
			// logout
			if (this.isLoggedIn) await this.logoutUser();
			// then sign-in
			await this.signinUser();
			//
		} else if (this.isLoggedIn) {
			// redirect to dashboard
			this.$router.push("/dashboard");
		}
	},
};
</script>
